import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import './assets/css/global.css'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import './assets/font/AlimamaFangYuan/font.css'


import VueDragResizeRotate from "@gausszhou/vue3-drag-resize-rotate";
import "@gausszhou/vue3-drag-resize-rotate/lib/bundle.esm.css";

const app = createApp(App);
app.use(ElementPlus)
app.use(router)
app.use(VueDragResizeRotate);
app.mount('#app')

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
<template>
  <router-view></router-view>
</template>

<style lang="less">
body{
  margin: 0 auto;
  width: 100%;
  height: 100%;
}
</style>
<script>
export default {
  name: 'App',
  data(){
    return{
    }
  },
  mounted() {
  }
}
</script>
import { createRouter, createWebHashHistory} from 'vue-router'

const router = createRouter({
    history : createWebHashHistory(),
    routes:[
        {
            path : '/',
            component:() => import('../view/index')
        },
        {
            path : '/card/tmpl',
            component:() => import('../view/card/tmpl')
        },
        {
            path : '/edu/index',
            component:() => import('../view/edu/index'),
            children:[
                {
                    path: '/edu/question',
                    component:() => import('../view/edu/question/index'),
                },
                {
                    path: '/edu/question/cate',
                    component:() => import('../view/edu/question/cate'),
                },
                {
                    path: '/edu/question/group',
                    component:() => import('../view/edu/question/group'),
                },
            ]
        },
        {
            path : '/mall',
            component:() => import('../view/mall/index'),
            children:[
                {
                    path: '/mall/category',
                    component:() => import('../view/mall/category')
                },
                {
                    path: '/mall/brand',
                    component:() => import('../view/mall/brand')
                },
                {
                    path: '/mall/product',
                    component:() => import('../view/mall/product')
                }
            ]
        },
    ]
});

export default router;